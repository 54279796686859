import React, { useEffect, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';
import { getUserData } from '../../Functions/Functions';

import {
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookMessengerShareButton,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerIcon,
} from 'react-share';

export default function BusinessShare({ onRequestClose, businessId }) {
  const { t } = useTranslation();
  const [ShareGiftImage, setShareGiftImage] = useState();
  const [userProfile, setUserProfile] = useState();
  const [user, setUser] = useState();
  const [loader, setLoader] = useState(false);
  const ref = useRef(null);

  useEffect(async () => {
    const userData = await getUserData();
    if (userData) {
      setLoader(true);
      setUser(userData);
    }
  }, []);

  useEffect(async () => {
    const userData = await getUserData();
    if (userData) {
      setLoader(true);
      setUser(userData);
    }
  }, []);

  var shareContent = `www.voteandfun.com/businessDetailVisitor/${businessId} Hey! Have a look at this shop \n\n Don't you have VoteAndFun app? \n
Download it now.\n\nfrom Play store 
https://play.google.com/store/apps/details?id=com.voteandfun  \n\n from App store 
https://apps.apple.com/in/app/very9/id6458651169`;

  const onButtonClick = (dref) => {
    console.log('window', window, dref);
    if (dref.current === null) {
      return;
    } else {
      if (window?.isNative) {
        return toPng(dref.current, { cacheBust: true })
          .then((dataUrl) => {
            setLoader(false);
            return dataUrl;
          })
          .catch((err) => {
            alert('download err===', err);
          });
      } else {
        toPng(dref.current, { cacheBust: true })
          .then((dataUrl) => {
            const link = document.createElement('a');
            console.log('image', dataUrl);
            setShareGiftImage(dataUrl);
            link.download = 'my-image-name.png';
            link.href = dataUrl;
            link.click();
          })
          .catch((err) => {
            alert('download err', err);
          });
      }
    }
  };

  const ShareView = ({ shareFunc = () => null, setLoading }) => {
    return (
      <>
        <div class="ss-wrap">
          <div class="ss-encl">
            <div class="ss-row">
              <div class="ss-blk">
                {window.isNative ? (
                  <FacebookIcon
                    size={32}
                    round
                    onClick={() => {
                      setLoading(true);
                      shareFunc().then((res) => {
                        setLoading(false);
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            messenger: true,
                            data: {
                              name: '',
                              link: shareContent,
                              imageBase64: '',
                            },
                          }),
                        );
                      });
                    }}
                  />
                ) : (
                  <FacebookMessengerShareButton
                    url={shareContent}
                    quote={'Vote and Fun Vote'}
                    hashtag={'#vote&fun'}
                    description={'aiueo'}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>
                )}
                <span>MESSANGER</span>
              </div>
              <div class="ss-blk">
                {window?.isNative ? (
                  <TelegramIcon
                    size={32}
                    round
                    onClick={() => {
                      setLoading(true);
                      shareFunc().then((res) => {
                        setLoading(false);
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            telegram: true,
                            data: {
                              name: '',
                              link: shareContent,
                              imageBase64: '',
                            },
                          }),
                        );
                      });
                    }}
                  />
                ) : (
                  <TelegramShareButton
                    url={shareContent}
                    quote={'Vote and Fun Vote'}
                    hashtag={'#vote&fun'}
                    description={'aiueo'}
                    className="Demo__some-network__share-button"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                )}
                <span>TELEGRAM</span>
              </div>

              <div class="ss-blk">
                {window.isNative ? (
                  <WhatsappIcon
                    size={32}
                    round
                    onClick={() => {
                      setLoading(true);
                      shareFunc().then((res) => {
                        setLoading(false);
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            whatsapp: true,
                            data: {
                              name: '',
                              link: shareContent,
                              imageBase64: '',
                            },
                          }),
                        );
                      });
                    }}
                  />
                ) : (
                  //   <WhatsappShareButton
                  //   url={shareContent}
                  //   quote={`Vote and Fun Vote ${user?.name}`}
                  //   hashtag={'#vote&fun'}
                  //   description={'aiueo'}
                  //   className="Demo__some-network__share-button"
                  // >
                  //   <WhatsappIcon size={32} round />
                  // </WhatsappShareButton>
                  // <WhatsappIcon
                  //   size={32}
                  //   round
                  //   onClick={() => {
                  //     setLoading(true);
                  //     shareFunc().then((res) => {
                  //       setLoading(false);

                  //       window.ReactNativeWebView.postMessage(
                  //         JSON.stringify({
                  //           whatsapp: true,
                  //           data: {
                  //             name: userProfile?.username,
                  //             link: shareContent,
                  //             imageBase64: res ?? '',
                  //           },
                  //         }),
                  //       );
                  //     });
                  //   }}
                  // />
                  <WhatsappShareButton
                    url={shareContent}
                    quote={`Vote and Fun Vote ${user?.name}`}
                    hashtag={'#vote&fun'}
                    description={'aiueo'}
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                )}
                <span>Whatsapp</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div class="modal-lay-wrap">
      <button class="btn btn-close-x">
        <img class="img-fluid" src="/images/close-x.svg" alt="ico" onClick={() => onRequestClose(false)} />
      </button>
      <h6>Message it to your friend!</h6>

      <ShareView shareFunc={() => onButtonClick(ref)} setLoading={setLoader} />
    </div>
  );
}
